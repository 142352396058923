import styled from 'styled-components';
import { AdvancedVideo } from '@cloudinary/react';
import { breakpoints, spacing } from '@naf/theme';
import { GridCol } from '@naf/grid';
import CtaButton from '../../../ctaButton/CtaButton';

export const FullScreenHeaderWrapper = styled.div`
  h1 {
    padding-top: ${spacing.space120};
  }

  @media (max-width: ${breakpoints.m}) {
    h1 {
      padding-top: ${spacing.space80};
    }
  }
`;

export const CenterBlock = styled.div`
  max-width: ${breakpoints.xl};
  padding: 0 ${spacing.space48};
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.l}) {
    padding: 0 ${spacing.space24};
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column-reverse;
  }
`;

export const HeroWrapper2 = styled(GridCol)`
  justify-content: center;
  align-items: center;
  grid-column-start: 4;

  @media (max-width: ${breakpoints.s}) {
    grid-column-start: 1;
  }

  h1 {
    margin-top: 0;
  }
`;

export const HeroWrapper3 = styled(GridCol)`
  justify-content: center;
  align-items: center;
  grid-column-start: 1;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const TextBox = styled.div`
  width: calc(50% - 112px);
  padding-right: 112px;

  @media (max-width: ${breakpoints.l}) {
    width: calc(50% - ${spacing.space48});
    padding-right: ${spacing.space48};
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    padding: 0;
    margin-top: ${spacing.space32};
  }
`;

export const StyledButton = styled(CtaButton)`
  margin-top: ${spacing.space16};
  @media (max-width: ${breakpoints.m}) {
    margin-top: 0;
  }
`;

export const ImageBox = styled.figure`
  width: 50%;
  max-width: 539px;
  object-fit: cover;
  margin: 0;
  text-align: right;

  img {
    width: 100%;
    object-fit: cover;
    @media (max-width: ${breakpoints.m}) {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const IllustrationBox = styled.figure`
  width: 50%;
  max-width: 539px;
  object-fit: contain;
  margin: 0;
  text-align: right;

  img {
    width: 100%;
    object-fit: contain;
    @media (max-width: ${breakpoints.m}) {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledVideo = styled(AdvancedVideo)`
  max-width: 539px;
  object-fit: cover;
  margin: 0;
  text-align: right;

  video {
    width: 100%;
    height: auto;
    object-fit: cover;

    @media (max-width: ${breakpoints.m}) {
      width: 100%;
      height: auto;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100%;
    max-width: 100%;
  }
`;
