import React, { useCallback, useEffect, useState } from 'react';
import { breakpoints } from '@naf/theme';
import { AdvancedImage, responsive, lazyload, placeholder } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { useCloudinary } from '../../../../hooks/Cloudinary/useCloudinary';
import { useDevicePixelRatio } from '../../../../hooks/useDevicePixelRatio';
import { useWindowResize } from '../../../../hooks/Window/useWindowResize';
import { ImageBox, StyledVideo } from './styles';
import { CloudinaryImageType, CloudinaryVideoType } from '../../../../../../types/blockTypes';

export const HeroPictureImage = ({ image, video }: { image: CloudinaryImageType; video: CloudinaryVideoType }) => {
  const publicId = image?.public_id || image?.publicId || video?.publicId;
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();

  const [mainImage, setMainImage] = useState(
    (publicId &&
      cld
        .image(publicId)
        .resize(fill().width(539).height(464).gravity(autoGravity()))
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const cldVideo = video && cld.video(publicId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto');

  const setResponsiveImage = useCallback(() => {
    if (publicId && typeof window !== 'undefined') {
      const width = window.innerWidth <= parseInt(breakpoints.m, 10) ? Math.round(window.innerWidth) : 539;
      const height = window.innerWidth <= parseInt(breakpoints.m, 10) ? Math.round(width * 0.86) : 464;
      setMainImage(
        cld
          .image(publicId)
          .resize(fill().width(width).height(height).gravity(autoGravity()))
          .delivery(dpr(devicePixelRatio))
          .quality('auto:best')
          .format('auto'),
      );
    }
  }, [cld, devicePixelRatio, publicId]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  return (
    <ImageBox>
      {video && cldVideo ? (
        <StyledVideo
          cldVid={cldVideo}
          plugins={[responsive({ steps: 50 }), placeholder({ mode: 'blur' })]}
          controls={false}
          autoPlay
          muted
          playsInline
          loop
        />
      ) : (
        mainImage && (
          <AdvancedImage
            cldImg={mainImage}
            plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }), placeholder({ mode: 'blur' })]}
            alt={image.alt || image.altText || image.caption}
          />
        )
      )}
    </ImageBox>
  );
};
